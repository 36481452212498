<template>
  <span>
    {{amount}}
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  computed: {
    amount () {
      return this.value ? this.formatPercentage(this.value, 2) : ''
    }
  },
  props: {
    value: {
      type: Number
    }
  }
}
</script>
